
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import EstimateService from "@/services/estimate/estimate.service";
import EstimatePrintComponent from "@/components/EstimatePrintComponent.vue";
import UpdateEvent from "@/models";
import EstimateDownloadModal from "@/modals/estimate/EstimateDownloadModal.vue";

export default mixins(MixinsPage).extend({
  name: "Estimate",

  components: {
    EstimateDownloadModal,
    EstimatePrintComponent,
  },
  data: () => ({
    estimate: null as any,
    scheduleList: null as any,
    download: {
      index: 0,
      uriList: [] as string[],
    },
    modal: {
      estimate: {
        visible: false,
        params: {
          uriList: [] as string[],
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    estimateType: "",
  }),
  mounted() {
    this.$nextTick(() => {
      this.getEstimate();
    });
  },
  methods: {
    contractStatusToHtml(value) {
      if (value === "WAIT") return "<span class='red--text'>계약 대기</span>";
      else if (value === "COMPLETE") return "<span class='green--text'>계약 완료</span>";
      else if (value === "CANCEL") return "<span class='black--text'>계약 취소</span>";
      else if (value === "SPECIAL_PRICE") return "<span class='orange--text'>특가표</span>";
      else if (value === "TEMPORARY") return "<span class='orange--text'>임시 계약</span>";
      return "알 수 없음";
    },
    async getEstimate() {
      core.loader.show();
      try {
        const type = this.$route.query.type ? String(this.$route.query.type) : "";
        this.estimateType = type;
        // console.log("type : ", type);

        const estimate = (await EstimateService.get(type)) as any;
        this.estimate = estimate;
        // console.log("estimate : ", estimate);
        this.scheduleList = estimate.scheduleList;
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
    async requestDownload() {
      this.download.index = 0;
      this.download.uriList = [];
      try {
        core.loader.show("파일 생성중...");
        const result = (await EstimateService.downloadImage(this.estimateType)) as any;
        if (result.downloadUriList) {
          this.download.index = 0;
          this.download.uriList = result.downloadUriList;
        }
      } catch (e) {
        console.log(e);
      } finally {
        core.loader.hide();
      }
      if (this.download.uriList.length === 0) {
        core.alert.show({
          title: "알림",
          body: "견적서 이미지 파일 생성 실패",
        });
      } else {
        const modal = this.modal.estimate;
        modal.params.uriList = this.download.uriList;
        modal.visible = true;
      }
    },
  },
});

import core from "@/core";

function _location(type: string, options: any) {
  return new Promise((resolve: any, reject) => {
    let count = 0;

    const success = function (position: any) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log(`position latitude: ${latitude}, longitude: ${longitude}`);
      resolve({
        latitude: latitude,
        longitude: longitude,
      });
    };
    const error = function (error: any) {
      if (type === "watch" && count > 0) {
        return;
      }
      count++;
      reject(error);

      if (error.code == 1) {
        core.alert.show({
          title: "알림",
          body: "위치 권한이 없어 내 위치 정보를 가져올 수 없습니다",
        });
      } else if (error.code == 2) {
        core.alert.show({
          title: "알림",
          body: "내 위치 정보 획득에 실패했습니다",
        });
      } else if (error.code == 3) {
        core.alert.show({
          title: "알림",
          body: "내 위치 정보 획득시간이 초과했습니다",
        });
      } else {
        console.log("unknown error: ", error);
      }
    };

    if (options == null) {
      options = {
        enableHighAccuracy: true,
        timeout: 30000,
        maximumAge: 0,
      };
    }

    if (type === "current") {
      navigator.geolocation.getCurrentPosition(success, error, options);
    } else if (type === "watch") {
      const watchId = navigator.geolocation.watchPosition(success, error, options);
    } else {
      console.log("unknown type: ", type);
    }
  });
}

class CoreLocation {
  currentPosition(options?: any) {
    return _location("current", options);
  }

  watchPosition(options?: any) {
    return _location("watch", options);
  }
}

export default new CoreLocation();

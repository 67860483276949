
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";

export default mixins(MixinsModalForm).extend({
  name: "SupportAsSelectCategoryModal",
  data: () => ({
    type: "",
    form: {
      name: "",
    },
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {});
  },
  methods: {},
});

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ko from "vuetify/src/locale/ko";
import "@mdi/font/css/materialdesignicons.css";
import "font-awesome/css/font-awesome.css";
import "@/assets/flaticon/flaticon-custom.css";

Vue.use(Vuetify);

export default new Vuetify({
  // theme: {
  //   themes: {
  //     light: {
  //       primary: "#ff8000",
  //     },
  //   },
  // },
  lang: {
    locales: { ko },
    current: "ko",
  },
  icons: {
    iconfont: "mdi",
  },
});


import mixins from "vue-typed-mixins";

export default mixins().extend({
  name: "BottomBar",
  data: () => ({}),
  mounted() {
    this.$nextTick(() => {
      const el = this.$refs.bottomBar as any;
      if (el != null) {
        // console.log(vue.$el.clientHeight);
        this.$emit("update:height", el.clientHeight);
      }
    });
  },
});

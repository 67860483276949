import store from "@/store";
import core from "@/core";
import Vue from "vue";
import { RouterBeforeTaskItem } from "@/plugins/vue-page-stack/router-before-task";

export default Vue.extend({
  data: () => ({
    backButton: {
      changed: false,
      routerBeforeTaskItem: (null as never) as RouterBeforeTaskItem,
    },
  }),
  mounted() {
    const $this = this as any;
    const routerBeforeTask = store.state.app.routerBeforeTask;
    let allowBackCloseEvent = $this?.properties?.allowBackCloseEvent;
    if (allowBackCloseEvent == null) allowBackCloseEvent = true;
    this.backButton.routerBeforeTaskItem = new RouterBeforeTaskItem($this.$vnode.tag, () => {
      if ($this.backButton.changed) {
        core.alert
          .show({
            title: "확인",
            body: "뒤로가시면 작성 하신 내용이 취소됩니다.<br>계속 진행하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "예",
            cancelButtonText: "아니오",
            allowBackCloseEvent: false,
          })
          .then((result) => {
            if (result === "confirm") {
              $this.properties.visible = false;
            }
          });
      } else if (allowBackCloseEvent) {
        $this.properties.visible = false;
      }
      return false;
    });
    routerBeforeTask.pushModal(this.backButton.routerBeforeTaskItem);
  },
  destroyed() {
    this.backButton.routerBeforeTaskItem.destroyed();
  },
});

import { ValidationObserver, ValidationProvider } from "vee-validate";
import Vue from "vue";
import core from "@/core";

// 샘플
// import { extend, localize } from "vee-validate";
// import { email, max, min, required } from "vee-validate/dist/rules";
// extend("required", required);
// extend("min", min);
// extend("max", max);
// extend("email", email);
// extend("password", {
//   params: ["target"],
//   validate(value, { target }) {
//     //console.log('value : ', value, ', target : ', target);
//     return value === target;
//   },
//   message: "비밀번호 확인이 일치하지 않습니다",
// });
// extend("phone", {
//   validate(value) {
//     return core.utils.validate.phone(value);
//   },
//   message: "휴대전화 형식이 올바르지 않습니다",
// });
// localize("ko", {
//   messages: Constant.validate.language.korea.messages,
//   names: {
//     username: "아이디 ",
//   },
// });
export default Vue.extend({
  components: {
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    // form field 유효성 검사
    async validate() {
      const observer = this.$refs.observer as any;
      if (observer) {
        const observerEl = observer.$el;
        const validate = await observer.validate();
        if (!validate) {
          const msgEl: HTMLElement | null = observerEl.querySelector(
            ".v-messages.error--text:first-of-type"
          );
          if (msgEl == null) {
            const errors = this.getValidateErrors() as any;
            for (const key of Object.keys(errors)) {
              const value = errors[key];
              if (value.length > 0) {
                await core.alert.show({
                  title: "알림",
                  body: value[0]
                });
                return;
              }
            }
          } else {
            const el: HTMLElement | null = observerEl.querySelector(".error--text:first-of-type");
            if (el != null) {
              el.focus();
              el.scrollIntoView();
            }
          }
        }

        return validate;
      } else {
        console.log("not found observer");
      }
    },
    resetValidate() {
      const observer = this.$refs.observer as any;
      if (observer) {
        observer.reset();
      } else {
        console.log("not found observer");
      }
    },
    getValidateErrors() {
      const observer = this.$refs.observer as any;
      return observer.errors;
    },
    // submit 오류 메시지 공통 처리
    errorSubmit(error: any) {
      const observer = this.$refs.observer as any;
      const errorFieldName = error.errorFieldName;
      if (errorFieldName != null) {
        const errors = observer.errors[errorFieldName];
        console.log(errors);
        if (errors != null) {
          errors.push(error.message);
        } else {
          console.log(`not found error field '${errorFieldName}'`);
        }
        console.log(observer.errors);
      }
    },
    addValidateError(key: string, message: string) {
      const observer = this.$refs.observer as any;
      const errors = observer.errors[key];
      console.log(observer.errors);
      if (errors != null) {
        errors.push(message);
      } else {
        console.log("not found error key : ", key);
      }
    }
  }
});


import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModal).extend({
  name: "SelectRatingModal",
  data: () => ({
    type: "",
    rating: null as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      this.rating = this.properties.params.value;
    });
  },
  methods: {
    async submit() {
      this.close(UpdateEventType.CONFIRM, this.rating);
    },
  },
});

import UserService from "@/services/user/user.service";
import core from "@/core";
import { AuthState, MobileSystemProperties, RootState } from "@/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import store from "@/store";

const state: AuthState = {
  user: null,
  sessionCheckTime: null,
  mobileToken: null,
  systemProperties: null,
};

export const getters: GetterTree<AuthState, RootState> = {
  user: (theState: AuthState) => {
    return async (reload: boolean | null) => {
      if (reload == null) reload = false;

      const curTime = new Date();
      if (theState.sessionCheckTime == null) {
        theState.sessionCheckTime = new Date(0);
      }

      if (!reload) {
        //console.log("call getUser");
        const elapsedTimeMillis = curTime.getTime() - theState.sessionCheckTime.getTime();
        //console.log(elapsedTimeMillis);
        if (elapsedTimeMillis < 60000) return theState.user;
      }

      try {
        theState.sessionCheckTime = curTime;
        const user = (await UserService.getMyInfo()) as any;
        if (user.roles.indexOf("CUSTOMER") > -1) {
          mutations["login"](theState, user);
          return user;
        } else {
          console.log("is not support role : ", user.roles);
          await UserService.logout();
        }
      } catch (reason) {
        if (reason === 401) {
          // actions.logout({ commit: commit });
        }
      }
      return null;
    };
  },
};

export const actions: ActionTree<AuthState, RootState> = {
  async login({ commit }, params) {
    const result = await UserService.login(params);
    state.sessionCheckTime = new Date(0);

    const user = await store.getters["auth/user"](true);
    //console.log("user : ", user);
    if (user != null) {
      return user;
    }
    await core.alert.show({
      title: "알림",
      body: "쿠키 저장 오류가 발생했습니다!",
      confirmButtonText: "확인",
    });
  },

  async logout({ commit }) {
    if (state.user != null) {
      try {
        // delete mobile device info
        //console.log("state.systemProperties : ", state.systemProperties);
        if (state.systemProperties != null) {
          const params: any = {
            crudType: "D",
            deviceToken: state.systemProperties?.deviceToken,
          };
          await UserService.updateMobileDeviceInfo(params);
        }

        return await UserService.logout();
      } catch (e) {
        console.log(e);
      } finally {
        commit("logout");
      }
    }
  },

  async updateMobileSystemProperties({ commit }, systemProperties: MobileSystemProperties) {
    state.mobileToken = systemProperties.deviceToken;
    if (state.user != null) {
      // delete mobile device info
      const params: any = core.utils.deepCopy(systemProperties);
      params.crudType = "C";
      await UserService.updateMobileDeviceInfo(params);

      commit("mobileSystemProperties", systemProperties);
    }
  },
};

const mutations: MutationTree<AuthState> = {
  async login(theState, user) {
    // console.log("mutations[login]: ", theState, user);
    console.log("update user info");
    theState.sessionCheckTime = new Date();
    theState.user = user;
    core.webSocket.init();

    //console.log("state.mobileToken : ", state.mobileToken);
    if (state.mobileToken) {
      const params: any = { deviceToken: state.mobileToken, crudType: "C" };
      await UserService.updateMobileDeviceInfo(params);
      const systemProperties = { deviceToken: params.deviceToken } as MobileSystemProperties;
      mutations.mobileSystemProperties(state, systemProperties);
    }
  },
  logout(theState) {
    //console.log('mutations[logout]: ', state.user);
    if (theState.user != null) {
      console.log("logout!");
      theState.sessionCheckTime = new Date();
      theState.user = null;
      core.webSocket.disconnect();
    }
  },

  mobileSystemProperties(state: any, systemProperties: MobileSystemProperties | null) {
    //console.log('mutations[logout]: ', state.user);
    state.systemProperties = systemProperties;
    if (systemProperties != null) {
      console.log("update system properties : ", JSON.stringify(systemProperties));
    }
  },
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

import korea from "vee-validate/dist/locale/ko.json";

class Constant {
  appName = "스마트라인";
  validate = {
    language: {
      korea: korea,
    },
  };
}

export default new Constant();

import core from "@/core";

class ReviewService {
  getScoreList() {
    return new Promise((resolve: any, reject) => {
      core.http
        .get(`/api/review/score`, null)
        .then((data: any) => {
          if (data.scoreList) {
            resolve(data.scoreList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  updateScore(params: any) {
    return new Promise((resolve: any, reject) => {
      const strParams = JSON.stringify(params);
      core.http
        .postJson(`/api/review/score`, strParams)
        .then((data: any) => {
          if (data.score) {
            resolve(data.score);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new ReviewService();


import Vue from "vue";

export default Vue.extend({
  name: "TopToolbarProps",
  props: {
    color: {
      type: String,
      default: "white",
    },
  },
});

import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";

/**
 * interface
 */
// Store root state
export interface RootState {
  topToolbar: TopToolbarState;
  app: AppState;
  auth: AuthState;
}

// Store modules state
export interface TopToolbarState {
  title: string;
}

export interface AppState {
  name: string;
  fromRoute?: string;
  size: WindowSize;
  routerBeforeTask: RouterBeforeTask;
  modal: {
    alert: AlertModal;
  };
  printClass: null | string;
}

export interface AuthState {
  user: User | null;
  sessionCheckTime: Date | null;
  mobileToken: string | null;
  systemProperties: MobileSystemProperties | null;
}

export interface MobileSystemProperties {
  timezone: string;
  locale: string;
  manufacturer: string;
  model: string;
  product: string;
  hardware: string;
  os: string;
  versionSdk: number;
  versionRelease: string;
  deviceToken: string;
  packageName: string;
  versionCode: number;
  versionName: string;
}

export interface User {
  id: number;
  username: string;
  email: string;
  name: string;
}

export interface WindowSize {
  width: number;
  height: number;
}

export interface Comment {
  type: CommentType;
}

export interface AlertModal {
  visible: boolean;
  title: string;
  body: string;
  showCancelButton: boolean;
  cancelButtonText: string;
  confirmButtonText: string;
  promise: {
    resolve: any;
    reject: any;
  };
  allowBackCloseEvent: boolean;
}

export interface TableRequest {
  draw: number;
  start: number;
  length: number;
  orderColumnName: string;
  order: TableOrder;
  searchColumns?: any;
}

/**
 * enum
 */
export enum CommentType {
  IMAGE = "I",
  TEXT = "T",
  SYSTEM = "S",
  LINK = "L",
  ADMIN = "A",
}

export enum UpdateEventType {
  RELOAD = "reload",
  FILTER = "filter",
  DELETE = "delete",
  UPDATE = "update",
  CONFIRM = "confirm",
  CANCEL = "cancel",
  PAGE = "page",
  TABLE = "table",
}

export enum EntityType {
  SCHEDULE = "WORK_SCHEDULE",
  ESTIMATE = "WORK_ESTIMATE",
  ESTIMATE_SUPPORT = "WORK_ESTIMATE_SUPPORT",
  SUPPORT_AS = "SUPPORT_AS",
}

export enum TableOrder {
  ASC = "asc",
  DESC = "desc",
}

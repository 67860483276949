
import mixins from "vue-typed-mixins";
import MixinsModalForm from "@/mixins/modal-form";
import CommentService from "@/services/comment/comment.service";

import core from "@/core";
import Constant from "@/store/constant";
import { localize } from "vee-validate";
import { UpdateEventType } from "@/types";

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    comment: "댓글 ",
  },
});

export default mixins(MixinsModalForm).extend({
  name: "CommentEditModal",
  data: () => ({
    formProcessing: false,
    form: {
      content: "",
    },
  }),
  mounted() {
    this.form.content = this.properties.params.item.content;
  },
  methods: {
    async submit() {
      if (await this.validate()) {
        core.loader.show("저장중...");
        try {
          const commentParam = this.properties.params.item;
          const comment = await CommentService.updateContent(commentParam.id, this.form.content);
          //console.log("update comment : ", comment);
          core.loader.hide();
          this.close(UpdateEventType.RELOAD);
        } catch (e) {
          core.loader.hide();
          this.errorSubmit(e);
        } finally {
          core.loader.hide();
        }
      }
    },
    async deleteItem() {
      const item = this.properties.params.item;
      const result = await core.alert.show({
        title: "확인",
        body: "삭제를 계속 진행하시겠습니까?",
        showCancelButton: true,
        cancelButtonText: "취소",
        confirmButtonText: "삭제",
      });
      if (result === "confirm") {
        core.loader.show("저장중...");
        try {
          const apiResult = await CommentService.delete(item.id);
          //console.log("apiResult : ", apiResult);
        } catch (e) {
          console.log(e);
        }
        core.loader.hide();
        this.close(UpdateEventType.RELOAD);
      }
    },
  },
});

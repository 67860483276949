
import Vue from "vue";
import store from "@/store";
import AppBar from "@/components/AppBar.vue";
import AlertModal from "@/modals/core/AlertModal.vue";
import { RouteMeta } from "@/router";
import core from "@/core";

export default Vue.extend({
  name: "App",
  components: { AlertModal, AppBar },
  data: () => ({
    transitionName: "forward",
    app: store.state.app,
    prop: {
      menuType: "",
      subMenu: null as any,
    },
  }),
  watch: {
    $route(to, from) {
      if (to.params["stackKey-dir"] === "forward") {
        this.transitionName = "forward";
      } else {
        this.transitionName = "back";
      }
      const meta = to.meta as RouteMeta;
      this.prop.menuType = meta.menuType;
      this.prop.subMenu = meta.subMenu;
      this.windowResize();
    },
  },

  mounted() {
    (window as any).showModal = this.showModal;
    (window as any).updateMobileSystemProperties = this.updateMobileSystemProperties;

    const windowResize = this.windowResize;
    window.addEventListener("resize", () => {
      windowResize();
    });
    windowResize();
  },
  methods: {
    windowResize() {
      // console.log(`resize width '${window.innerWidth}', '${window.innerHeight}'`);
      // console.log(`resize width '${window.outerWidth}', '${window.outerHeight}'`);
      this.app.size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      // document.body.style.minHeight = this.app.size.height + "px";
      //document.documentElement.style.setProperty("--vh", `${this.app.size.height * 0.01}px`);
    },
    showModal(params) {
      return core.alert.show(params);
    },
    updateMobileSystemProperties: function (strSystemProperties: string) {
      const systemProperties = JSON.parse(strSystemProperties);
      this.$store.dispatch("auth/updateMobileSystemProperties", systemProperties);
    },
  },
});

import core from "@/core";
import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import { UpdateEventType } from "@/types";
import UpdateEvent from "@/models";

/**
 * 페이지 공통 처리
 */
export default mixins(MixinsCommon).extend({
  mounted() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
  },
  methods: {
    getPageUpdateEvent() {
      const event = this.updateEvent;
      this.updateEvent = null;
      return event;
    },
    getComponentUpdateEvent(modal: any): UpdateEvent | null {
      if (modal == null) {
        console.log("invalid param");
        return null;
      }
      const event = modal.updateEvent;
      modal.updateEvent = null;
      return event;
    },
    goBack(type?: UpdateEventType, item?: any) {
      if (type != null) {
        this.updatePreVmEvent(type, item);
      }
      const $this = this as any;
      if ($this.backButton != null) {
        $this.backButton.changed = false;
      }

      if (window.history.length > 2) {
        this.$router.back();
      } else {
        this.$router.push("/");
      }
    },
    updatePreVmEvent(type: UpdateEventType, item?: any) {
      const vm = this.getPreStackVm();
      // console.log("vm : ", vm);
      if (vm != null) {
        vm.updateEvent = new UpdateEvent(type, this.$route.path, item);
      } else {
        console.log("not exists pre vm");
      }
    },
    replaceState(urlParams?: any) {
      if (urlParams == null) urlParams = {};
      urlParams.stackKey = this.$route.query.stackKey;
      const length = Object.keys(urlParams).length;
      const url = this.$route.path + (length > 0 ? "?" + core.http.objToUrlParams(urlParams) : "");
      history.replaceState("", "", url);
    },
    getPreStackVm() {
      let vm = this.$pageStack.getPreStackInstance();
      if (vm != null) {
        if (vm.$vnode.tag.indexOf("TheAppBarContainer") > -1) {
          if (vm.$children != null) {
            if (vm.$children.length > 0) {
              vm = vm.$children[vm.$children.length - 1];
              if (vm != null) {
                if (vm.$children != null) {
                  if (vm.$children.length > 0) {
                    vm = vm.$children[0];
                  }
                }
                return vm;
              }
            }
          }
        }
        return vm;
      }
      return null;
    },
    async notFound() {
      await core.alert.show({
        title: "알림",
        body: "잘못된 요청입니다.",
        allowBackCloseEvent: false
      });
      this.goBack(UpdateEventType.RELOAD);
    }
  }
});


import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import core from "@/core";
import store from "@/store";
import EstimateService from "@/services/estimate/estimate.service";
import { UpdateEventType } from "@/types";

export default mixins(MixinsModal).extend({
  name: "SupportScheduleModal",
  data: () => ({
    app: store.state.app,
    estimate: null as any,
    categoryList: [],
    unScheduledCategoryList: [],
    unScheduledCategoryText: "",
    dates: [],
    dateText: "",
    totalDay: 0,
  }),
  async mounted() {
    this.$nextTick(async () => {
      // 고객지원
      try {
        const estimate = (this.estimate = (await EstimateService.get()) as any);
        //console.log("estimate : ", estimate);
        const estimateDetailMap = {};
        const categoryMap = {};

        if (estimate.detailList != null) {
          estimate.detailList.forEach((detail) => {
            estimateDetailMap[detail.id] = detail;
            let category = categoryMap[detail.category.id];
            if (category == null) {
              category = detail.category;
            }
            categoryMap[category.id] = category;
          });
        }

        const scheduleList = estimate.scheduleList;
        if (scheduleList != null) {
          scheduleList.forEach((schedule) => {
            let category = categoryMap[schedule.category.id];
            if (category.scheduleList == null) {
              category.scheduleList = [];
            }
            category.scheduleList.push(schedule);

            if (schedule.detailList != null) {
              schedule.detailList.forEach((detail) => {
                //const estimateDetail = estimateDetailMap[detail.estimateDetailId];
                delete estimateDetailMap[detail.estimateDetailId];
              });
            }
          });
        }

        const unScheduledCategoryMap = {};
        for (const key of Object.keys(estimateDetailMap)) {
          const estimateDetail = estimateDetailMap[key];
          unScheduledCategoryMap[estimateDetail.category.id] = estimateDetail.category;
        }

        const unScheduledCategoryList = [] as any;
        this.unScheduledCategoryList = unScheduledCategoryList;
        for (const key of Object.keys(unScheduledCategoryMap)) {
          const unScheduledCategory = unScheduledCategoryMap[key];
          unScheduledCategoryList.push(unScheduledCategory);
        }
        console.log("unScheduledCategoryList : ", unScheduledCategoryList);

        const categoryList = [] as any;
        for (const key of Object.keys(categoryMap)) {
          const category = categoryMap[key];

          if (category.name == "새집증후군") {
            category.dayText = "2일";
            category.day = 2;
          } else {
            category.dayText = "1일";
            category.day = 1;
          }
          if (category.scheduleList) {
            let scheduleDateText = "";
            category.scheduleList.forEach((schedule) => {
              scheduleDateText += this.scheduleDateText(schedule) + ",";
            });
            if (scheduleDateText.length > 0) {
              category.scheduleDateText = scheduleDateText.substr(0, scheduleDateText.length - 1);
              if (unScheduledCategoryMap[category.id]) {
                category.scheduleDateText += ", 미정";
              } else {
                category.scheduleDateText += ", 확정";
              }
            } else {
              category.scheduleDateText = "미정";
            }
          } else {
            category.scheduleDateText = "미정";
          }
          categoryList.push(category);
        }
        this.categoryList = categoryList;

        unScheduledCategoryList.forEach((category) => {
          const _category = categoryMap[category.id];
          this.totalDay += _category.day;
        });
        console.log("totalDay : ", this.totalDay);
        //console.log("categoryList : ", categoryList);
      } catch (e) {
        console.log(e);
      }
    });
  },
  watch: {
    dates(val, preVal) {
      if (val != null) {
        this.dateText = this.dateListToText(val);
      } else {
        this.dateText = "";
      }
    },
  },
  methods: {
    dateListToText(dateList) {
      let text = "";
      const itemList = this.getDateItemList(dateList);
      itemList.forEach((item) => {
        let rangeDate = false;
        if (item.endAt != null) {
          const days = item.endAt.diff(item.startAt, "days");
          if (days > 0) {
            rangeDate = true;
          }
        }

        if (rangeDate) {
          text += `${item.startAt.format("YYYY년 MM월 DD일")} ~ ${item.endAt.format(
            "YYYY년 MM월 DD일"
          )}, `;
        } else {
          text += `${item.startAt.format("YYYY년 MM월 DD일")}, `;
        }
      });
      if (text.length > 1) {
        text = text.substr(0, text.length - 2);
      }
      return text;
    },
    getDateItemList(dateList) {
      const sortDateList = this.sortDateList(dateList) as any;

      const itemList = [] as any;
      let preDateMoment = null as any;
      let item = null as any;
      sortDateList.forEach((date) => {
        if (item != null) {
          const moment = core.date.instance(date);
          const days = moment.diff(preDateMoment, "days");
          if (days > 1) {
            item.endAt = preDateMoment;
            itemList.push(item);
            preDateMoment = null;
            item = null;
          }
        }

        if (item == null) {
          item = {
            startAt: core.date.instance(date),
            endAt: null,
          } as any;
        }
        preDateMoment = core.date.instance(date);
      });

      if (preDateMoment != null) {
        item.endAt = preDateMoment;
      }

      if (item != null) {
        itemList.push(item);
      }
      return itemList;
    },
    sortDateList(dateList) {
      const timeList = [] as any;
      dateList.forEach((date) => {
        timeList.push(core.date.instance(date).toDate().getTime());
      });
      timeList.sort((a, b) => {
        return a - b;
      });
      const sortDateList = [] as any;
      timeList.forEach((time) => {
        sortDateList.push(core.date.instance(new Date(time)).format("YYYY-MM-DD"));
      });
      return sortDateList;
    },
    async submit() {
      //console.log("전송");
      const dates = this.dates;
      if (dates.length == 0) {
        const result = await core.alert.show({
          title: "확인",
          body: `선택된 일정이 없습니다`,
        });
        return;
      } else if (dates.length < this.totalDay) {
        let diffDay = this.totalDay - dates.length;
        const result = await core.alert.show({
          title: "확인",
          body: `예상되는 시공기간을 계산했을때 ${diffDay}일 더 여유 일정이 필요합니다.<br>계속 진행하시겠습니까?`,
          showCancelButton: true,
        });
        if (result !== "confirm") {
          return;
        }
      }

      // submit
      const dateList = this.sortDateList(dates);
      this.close(UpdateEventType.CONFIRM, dateList);
    },
    minDate() {
      return core.date.instance().format("YYYY-MM-DD");
    },
    scheduleDateText(item) {
      const startDate = core.date.instance(item.startAt).format("YYYY-MM-DD");
      const endDate = core.date.instance(item.endAt).format("YYYY-MM-DD");
      if (startDate == endDate) {
        return core.date.instance(item.startAt).format("YYYY년 MM월 DD일");
      } else {
        return (
          core.date.instance(item.startAt).format("YYYY년 MM월 DD일") +
          " ~ " +
          core.date.instance(item.endAt).format("YYYY년 MM월 DD일")
        );
      }
    },
  },
});

import mixins from "vue-typed-mixins";
import MixinsCommon from "@/mixins/single/common";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";

export interface ComponentProperties {
  visible: boolean;
  params: any | null;
  // 업데이트 이벤트 처리용 변수
  updateEvent: UpdateEvent | null;
}

/**
 * 컴포넌트 공통 처리
 */
export default mixins(MixinsCommon).extend({
  props: {
    properties: {
      type: Object as () => ComponentProperties,
      default: () => {
        return {
          visible: false,
          params: {} as any,
          // 업데이트 이벤트 처리용 변수
          updateEvent: (null as any) as UpdateEvent | null,
        } as ComponentProperties;
      },
    },
  },
  methods: {
    getPropsUpdateEvent() {
      const props = this.properties;
      const event = props.updateEvent;
      props.updateEvent = null;
      return event;
    },
    setPropsUpdateEvent(result: UpdateEventType, item?) {
      this.properties.updateEvent = new UpdateEvent(result, "modal", item);
    },
    getComponentUpdateEvent(component: any) {
      if (component == null) {
        console.log("invalid param");
        return;
      }
      const event = component.updateEvent;
      component.updateEvent = null;
      return event;
    },
  },
});

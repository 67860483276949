import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState, TopToolbarState } from "@/types";

const state: TopToolbarState = {
  title: "Toolbar title",
};

export const getters: GetterTree<TopToolbarState, RootState> = {
  title: (theState: TopToolbarState) => {
    return theState.title;
  },
};

const mutations: MutationTree<TopToolbarState> = {
  setTitle(theState: TopToolbarState, title: string) {
    theState.title = title;
  },
};

export const actions: ActionTree<TopToolbarState, RootState> = {
  changeTitle({ commit }, title: string) {
    commit("setTitle", title);
  },
};

export const topToolbar: Module<TopToolbarState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

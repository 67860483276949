import { UpdateEventType } from "@/types";

export default class UpdateEvent {
  result: UpdateEventType;
  path: string;
  item?: any;

  constructor(result: UpdateEventType, path: string, item?: any) {
    this.result = result;
    this.path = path;
    this.item = item;
  }
}

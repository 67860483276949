
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";

export default mixins(MixinsPageForm).extend({
  name: "BeforeGroutInfo",
  components: {},
  data: () => ({
    app: store.state.app,
    beforeGroutImageList: [
      "/img/grout/1.jpg?v=20240812",
      "/img/grout/2.jpg?v=20240812",
      "/img/grout/3.jpg?v=20240816",
      "/img/grout/4.jpg?v=20240812",
      "/img/grout/5.jpg?v=20240812",
      "/img/grout/6.jpg?v=20240812",
    ],
    viewerOptions: {
      inline: false,
      button: true,
      navbar: true,
      title: false,
      toolbar: true,
      tooltip: true,
      movable: true,
      zoomable: true,
      rotatable: false,
      scalable: false,
      transition: true,
      fullscreen: true,
      keyboard: false,
    },
  }),
  async mounted() {
    this.$nextTick(async () => {});
  },
  methods: {},
});

import _history from "../history";
import config from "../config";

function isDef(v: any) {
  return v !== undefined && v !== null;
}

function isAsyncPlaceholder(node: any) {
  return node.isComment && node.asyncFactory;
}

function getFirstComponentChild(children: any) {
  if (Array.isArray(children)) {
    for (let i = 0; i < children.length; i++) {
      const c = children[i];
      if (isDef(c) && (isDef(c.componentOptions) || isAsyncPlaceholder(c))) {
        return c;
      }
    }
  }
}

const stack: any = [];

function getIndexByKey(key: any) {
  for (let index = 0; index < stack.length; index++) {
    if (stack[index].key === key) {
      return index;
    }
  }
  return -1;
}

const VuePageStack = (keyName: string) => {
  return {
    name: config.componentName,
    abstract: true,
    data() {
      return {};
    },
    props: {
      max: {
        type: [String, Number],
        default() {
          return "";
        },
      },
    },
    render() {
      //console.log("render");
      const $this = this as any;
      const key = $this.$route.query[keyName];
      const slot = $this.$slots.default;
      const vnode = getFirstComponentChild(slot);
      if (!vnode) {
        return vnode;
      }
      const index = getIndexByKey(key);
      if (index !== -1) {
        const lastIndex = stack.length - 1;
        if (index === lastIndex) {
          vnode.componentInstance = stack[index].vnode.componentInstance;
          stack[index] = null;
          stack.splice(index);
          stack.push({ key, vnode });
          return vnode;
        } else {
          const vm = stack[index].vnode.componentInstance;
          //console.log(vm.$route.meta);
          if (vm.$route.meta.pageStack) {
            //console.log("pageStack");
            vnode.componentInstance = stack[index].vnode.componentInstance;
          }
          // destroy the instances that will be spliced
          for (let i = index + 1; i < stack.length; i++) {
            stack[i].vnode.componentInstance.$destroy();
            stack[i] = null;
          }
          stack.splice(index + 1);
        }
      } else {
        if (_history.action === config.replaceName && stack.length > 0) {
          // destroy the instance
          stack[stack.length - 1].vnode.componentInstance.$destroy();
          stack[stack.length - 1] = null;
          stack.splice(stack.length - 1);
        }
        stack.push({ key, vnode });
      }
      vnode.data.keepAlive = true;
      return vnode;
    },
  };
};

function getStack() {
  return stack;
}

function getPreStack() {
  if (stack.length > 1) {
    return stack[stack.length - 2];
  }
  return null;
}

function getPreStackInstance() {
  const stack = getPreStack();
  if (stack != null) return stack.vnode.componentInstance;
  return null;
}

export { VuePageStack, getIndexByKey, getStack, getPreStack, getPreStackInstance };

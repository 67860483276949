import core from "@/core";
import { TableRequest } from "@/types";

class EstimateService {
  get(type?: string) {
    const params = {} as any;
    if (type) {
      params.type = type;
    }
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/work/estimate`, null, params)
        .then((data: any) => {
          if (data.estimate != null) {
            if (data.scheduleList != null) {
              data.estimate.scheduleList = data.scheduleList;
            }
            if (data.specialPrice != null) {
              data.estimate.specialPrice = data.specialPrice;
            }
            if (data.eventWinnerList != null) {
              data.estimate.eventWinnerList = data.eventWinnerList;
            }
            resolve(data.estimate);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getPaymentList() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/work/estimate/payment`)
        .then((data: any) => {
          if (data.paymentList != null) {
            resolve(data.paymentList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  downloadImage(type?: string) {
    const params = {} as any;
    if (type) {
      params.type = type;
    }
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/work/estimate/image`, null, params)
        .then((data) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  createSupportAs(categoryId: number) {
    return new Promise((resolve, reject) => {
      const strParams = JSON.stringify({ categoryId: categoryId });
      core.http
        .postJson(`/api/work/estimate/support/as`, strParams)
        .then((data: any) => {
          if (data.support != null) {
            resolve(data.support);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getSupportAsTable(params: TableRequest) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/work/estimate/support/as/table`, null, params)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getSupportAs(id: number) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/work/estimate/support/as/${id}`, null)
        .then((data: any) => {
          if (data.support != null) {
            resolve(data.support);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getSupport() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/work/estimate/support`, null)
        .then((data: any) => {
          if (data.support != null) {
            resolve(data.support);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new EstimateService();


import Vue from "vue";

export default Vue.extend({
  name: "AppBarProps",
  props: {
    prop: {
      type: Object,
      default: () => {
        return {
          menuType: {
            type: String,
            default: "back", // back, navigation
          },
          subMenu: {
            icon: {
              visible: false,
              list: [],
            },
            text: {
              visible: false,
              width: 80,
              list: [],
            },
          },
        };
      },
    },
  },
});

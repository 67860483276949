// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuePageStack from "@/plugins/vue-page-stack";

import { extend } from "vee-validate";
import { email, max, min, required } from "vee-validate/dist/rules";
import core from "@/core";

import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";
import TopToolbar from "@/components/TopToolbar.vue";

import firebase from "firebase/app";
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";
import "firebase/messaging";
import { MobileSystemProperties } from "@/types";

try {
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyB1-5w6m11m8hsLn_1c6j7abIRzYGkYR1M",
    authDomain: "smartline-992d8.firebaseapp.com",
    databaseURL: "https://smartline-992d8.firebaseio.com",
    projectId: "smartline-992d8",
    storageBucket: "smartline-992d8.appspot.com",
    messagingSenderId: "576950966065",
    appId: "1:576950966065:web:b5a91f9c44d973efb9c210",
    measurementId: "G-CJB4PJQ53B",
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  const messaging = firebase.messaging();
  // 알림 수신을 위한 사용자 권한 요청
  Notification.requestPermission().then((permission) => {
    console.log("permission ", permission);
    if (permission !== "granted") {
      core.alert.show({
        title: "확인",
        body: "알림을 허용해주세요!",
      });
    }
  });

  messaging.getToken().then((token) => {
    //console.log("token : ", token);
    if (token != null) {
      const systemProperties = { deviceToken: token } as MobileSystemProperties;

      (window as any).updateMobileSystemProperties(JSON.stringify(systemProperties));
    }
  });

  // Handle received push notification at foreground
  messaging.onMessage((payload) => {
    const data = payload.data;
    if (data != null) {
      //console.log("data : ", data);

      const notificationTitle = data.title;
      const notificationOptions = {
        body: data.body,
        icon: "/img/icons/android-icon-192x192.png",
      };

      const route = router.currentRoute;
      //console.log("dataPath : ", data.path, ", routePath : ", route.path);
      if (data.path !== route.path) {
        const notification = new Notification(notificationTitle, notificationOptions);
      }
    }
  });
} catch (e) {
  console.log(e);
}

Vue.config.productionTip = false;

Vue.component("top-toolbar", TopToolbar);

if (!("toJSON" in Error.prototype))
  Object.defineProperty(Error.prototype, "toJSON", {
    value: function () {
      const alt = {};

      Object.getOwnPropertyNames(this).forEach(function (key) {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          alt[key] = this[key];
        } catch (e) {
          alt[key] = e;
        }
      }, this);

      return alt;
    },
    configurable: true,
    writable: true,
  });

Vue.config.errorHandler = (error, vm, info) => {
  //console.log("error : ", error);
  core.http.sendError(error);
};

Vue.use(Viewer);

// page stack(이전 페이지 저장 처리)
Vue.use(VuePageStack, {
  router,
  routerBeforeTask: store.state.app.routerBeforeTask,
  name: "vue-page-stack",
  keyName: "stackKey",
});

// validate
extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("phone", {
  validate(value) {
    return core.utils.validate.phone(value);
  },
  message: "휴대전화 형식이 올바르지 않습니다",
});
extend("password", {
  params: ["target"],
  validate(value, params) {
    const target = (params as any).target;
    //console.log('value : ', value, ', target : ', target);
    return value === target;
  },
  message: "비밀번호 확인이 일치하지 않습니다",
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

import moment from "moment";

class Format {
  elapsedTime(preTime: string) {
    const curTime = moment();
    let diffTime = curTime.diff(preTime, "minute");
    let elapsedTime = diffTime + "분전";
    //console.log(diffTime);
    if (diffTime > 525600) {
      diffTime = curTime.diff(preTime, "year");
      if (diffTime <= 0) diffTime = 1;
      elapsedTime = diffTime + "년전";
    } else if (diffTime > 43200) {
      diffTime = curTime.diff(preTime, "month");
      if (diffTime <= 0) diffTime = 1;
      elapsedTime = diffTime + "달전";
    } else if (diffTime > 1440) {
      diffTime = curTime.diff(preTime, "days");
      if (diffTime <= 0) diffTime = 1;
      elapsedTime = diffTime + "일전";
    } else if (diffTime > 60) {
      diffTime = curTime.diff(preTime, "hour");
      if (diffTime <= 0) diffTime = 1;
      elapsedTime = diffTime + "시간전";
    }
    return elapsedTime;
  }

  comment(strDate: string) {
    const curDate = moment();
    const date = moment(strDate);
    if (curDate.year() == date.year()) {
      // 년도 동일 경우 월. 일. 오전/오후 시:분
      if (curDate.month() == date.month() && curDate.date() == date.date()) {
        return date.format("a hh:mm");
      } else {
        return date.format("MM.DD. a hh:mm");
      }
    } else {
      return date.format("YYYY.MM.DD. a hh:mm");
    }
  }
}

class CoreDate {
  constructor() {
    moment.locale("ko");
  }

  format = new Format();

  instance(params?: moment.MomentInput, onlyDate?: boolean) {
    if (onlyDate == null) {
      onlyDate = false;
    }
    if (onlyDate) {
      const _moment = moment(params);
      return moment(_moment.format("YYYY-MM-DD"));
    } else {
      return moment(params);
    }
  }

  calendar(
    strStartAt: string,
    strEndAt: string,
    strPreStartAt?: string | null,
    strPreEndAt?: string | null
  ) {
    if (typeof strPreStartAt === undefined) {
      strPreStartAt = null;
    }
    if (typeof strPreEndAt === undefined) {
      strPreEndAt = null;
    }

    console.log(
      `startAt ${strStartAt}, endAt ${strEndAt}, preStartAt ${strPreStartAt}, preEndAt ${strPreEndAt}`
    );

    const startAt = this.instance(strStartAt);
    const endAt = this.instance(strEndAt);
    const preStartAt = strPreStartAt == null ? null : this.instance(strPreStartAt);
    const preEndAt = strPreEndAt == null ? null : this.instance(strPreEndAt);

    if (startAt.isValid() && endAt.isValid()) {
      if (preStartAt != null && preStartAt.isValid()) {
        const diffTime = endAt.toDate().getTime() - preStartAt.toDate().getTime();
        //console.log("시작시간 변경, diffTime : ", diffTime);
        if (diffTime >= 0) {
          // 기존 시작 ~ 종료 일정 차이를 계산
          const newEndAt = this.instance(startAt.toDate().getTime() + diffTime);
          //console.log(newEndAt);
          return {
            startAt: startAt.format("YYYY-MM-DDTHH:mm:ss"),
            endAt: newEndAt.format("YYYY-MM-DDTHH:mm:ss"),
          };
        } else {
          // 종료일정 알수없음, 시작일정으로 변경
          let resultEndAt = "";
          if (startAt.isAfter(endAt)) {
            if (startAt.format("HHmm") != "0000" && endAt.format("HHmm") != "0000") {
              resultEndAt = endAt.add(-1, "hours").format("YYYY-MM-DDTHH:mm:ss");
            } else {
              resultEndAt = strStartAt;
            }
          } else {
            resultEndAt = strEndAt;
          }
          return {
            startAt: strStartAt,
            endAt: resultEndAt,
          };
        }
      } else if (preEndAt != null && preEndAt.isValid()) {
        const diffTime = preEndAt.toDate().getTime() - startAt.toDate().getTime();
        //console.log("종료시간 변경, diffTime : ", diffTime);
        if (diffTime < 0) {
          // 기존 시작 ~ 종료 일정 차이를 계산
          const newStartAt = this.instance(endAt.toDate().getTime() - diffTime);
          //console.log(newEndAt);
          return {
            startAt: newStartAt.format("YYYY-MM-DDTHH:mm:ss"),
            endAt: endAt.format("YYYY-MM-DDTHH:mm:ss"),
          };
        } else {
          let resultStartAt = "";
          if (endAt.isBefore(startAt)) {
            if (startAt.format("HHmm") != "0000" && endAt.format("HHmm") != "0000") {
              resultStartAt = endAt.add(-1, "hours").format("YYYY-MM-DDTHH:mm:ss");
            } else {
              resultStartAt = strEndAt;
            }
          } else {
            resultStartAt = strStartAt;
          }
          return {
            startAt: resultStartAt,
            endAt: strEndAt,
          };
        }
      } else {
        return {
          startAt: strStartAt,
          endAt: strEndAt,
        };
      }
    } else {
      console.log(
        `invalid time, startAt '${strStartAt}', endAt '${strEndAt}', preStartAt '${strPreStartAt}', preEndAt '${strPreEndAt}'`
      );
      return {
        startAt: strStartAt,
        endAt: strEndAt,
      };
    }
  }
}

export default new CoreDate();

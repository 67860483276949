import { ActionTree, Module } from "vuex";
import { AppState, RootState } from "@/types";
import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import Constant from "@/store/constant";

const state: AppState = {
  name: Constant.appName,
  fromRoute: undefined,
  size: { width: 0, height: 0 },
  routerBeforeTask: new RouterBeforeTask(),
  modal: {
    alert: {
      visible: false,
      title: "알림",
      body: "내용",
      showCancelButton: false,
      cancelButtonText: "취소",
      confirmButtonText: "확인",
      promise: {
        resolve: null,
        reject: null,
      },
      allowBackCloseEvent: true,
    },
  },
  printClass: null,
};

// export const getters: GetterTree<App, RootState> = {
//   title: (theState: TopToolbarState) => {
//     return theState.title;
//   },
// };

// const mutations: MutationTree<TopToolbarState> = {
//   setTitle(theState: TopToolbarState, title: string) {
//     theState.title = title;
//   },
// };
export const actions: ActionTree<AppState, RootState> = {
  addPrint({ commit }, deviceType: string) {
    if (state.printClass == null) {
      document.documentElement.classList.add("print");
      document.documentElement.classList.add(deviceType);
      state.printClass = deviceType;
    }
  },
  clearPrint({ commit }) {
    if (state.printClass) {
      document.documentElement.classList.remove("print");
      document.documentElement.classList.remove("mobile");
      document.documentElement.classList.remove("pc");
      state.printClass = null;
    }
  },
};

export const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  // getters,
  // mutations,
  actions,
};

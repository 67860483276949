import store from "@/store";
import core from "@/core/index";

interface Alert {
  // 제목
  title?: string;
  // 내용
  body?: string;
  // 취소 버튼 보이기
  showCancelButton?: boolean;
  // 취소 버튼 텍스트
  cancelButtonText?: string;
  // 확인 버튼 텍스트
  confirmButtonText?: string;
  // back 이벤트로 모달 닫기 허용
  allowBackCloseEvent?: boolean;
}

class CoreAlert {
  async show(params: Alert) {
    core.loader.hide();

    if (params.allowBackCloseEvent == null) {
      params.allowBackCloseEvent = true;
    }

    return new Promise((resolve, reject) => {
      const modal = store.state.app.modal.alert;

      if (params.title == null) params.title = "알림";
      modal.title = params.title;

      if (params.body == null) params.body = "";
      modal.body = params.body;

      if (params.showCancelButton == null) params.showCancelButton = false;
      modal.showCancelButton = params.showCancelButton;

      if (params.cancelButtonText == null) params.cancelButtonText = "취소";
      modal.cancelButtonText = params.cancelButtonText;

      if (params.confirmButtonText == null) params.confirmButtonText = "확인";
      modal.confirmButtonText = params.confirmButtonText;

      modal.promise.resolve = resolve;
      modal.promise.reject = reject;

      modal.allowBackCloseEvent = params.allowBackCloseEvent as boolean;

      modal.visible = true;
    });
  }
}

export default new CoreAlert();

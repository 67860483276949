
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import EstimateService from "@/services/estimate/estimate.service";
import ReviewService from "@/services/review/review.service";
import core from "@/core";
import SelectRatingModal from "@/modals/core/SelectRatingModal.vue";
import { UpdateEventType } from "@/types";

export default mixins(MixinsPageForm).extend({
  name: "Review",
  components: {
    SelectRatingModal,
  },
  data: () => ({
    app: store.state.app,
    title: "고객후기",
    id: 0,
    width: 0,

    estimate: null as any,
    reviewScoreList: null as any,
  }),
  async mounted() {
    this.$nextTick(async () => {
      this.width = this.app.size.width - 20;
      // 고객지원
      try {
        const estimate = (this.estimate = (await EstimateService.get()) as any);

        const reviewScoreList = (await ReviewService.getScoreList()) as any;

        const list = [] as any;
        estimate.categoryList.forEach((categoryItem) => {
          //console.log("categoryItem : ", categoryItem);
          let reviewScore = null as any;
          reviewScoreList.some((_reviewScore) => {
            if (_reviewScore.categoryId == categoryItem.category.id) {
              reviewScore = _reviewScore;
              return true;
            }
          });

          if (reviewScore == null) {
            reviewScore = {
              estimateId: estimate.id,
              categoryId: categoryItem.category.id,
              score: 3,
              remark: "",
            } as any;
          } else {
            reviewScore.score = reviewScore.score / 10;
          }
          reviewScore.category = categoryItem.category;

          list.push(reviewScore);
        });

        this.reviewScoreList = list;
        console.log("reviewScoreList : ", list);
      } catch (e) {
        console.log(e);
      }
    });
  },
  watch: {
    "app.size"() {
      this.width = this.app.size.width - 20;
    },
  },
  methods: {
    async submit() {
      const list = this.reviewScoreList;
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        await this.updateScore(item);
      }
      const result = await core.alert.show({
        title: "알림",
        body: "저장이 완료되었습니다.",
      });
      //console.log("result : ", result);
      this.goBack(UpdateEventType.CONFIRM);
    },
    async updateScore(params) {
      //console.log("update score : ", params);
      try {
        const _params = core.utils.deepCopy(params);
        if (_params.score != null) {
          _params.score = _params.score * 10;
        }

        const reviewScore = await ReviewService.updateScore(_params);
        //console.log("reviewScore : ", reviewScore);
      } catch (e) {
        console.log(e);
      }
    },
  },
});

import core from "@/core";

class StatusService {
  getStatusList() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/work/status`)
        .then((data: any) => {
          const statusList = data.statusList;
          if (statusList != null) {
            statusList.forEach((status: any) => {
              let text = "";
              if (status.type === "B") {
                text = "시공전";
              } else if (status.type === "A") {
                text = "시공후";
              } else if (status.type === "F") {
                text = "하자";
              } else if (status.type === "S") {
                text = "색상 선택";
              }
              status.title = `[${status.schedule.category.name}] ${text} ${status.title}`;
            });
            resolve(statusList);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getStatus(id: number) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/work/status/${id}`)
        .then((data: any) => {
          //console.log(data);
          const status = data.status;
          if (status != null) {
            let text = "";
            if (status.type === "B") {
              text = "시공전";
            } else if (status.type === "A") {
              text = "시공후";
            } else if (status.type === "F") {
              text = "하자";
            } else if (status.type === "S") {
              text = "색상 선택";
            }
            status.title = `[${status.schedule.category.name}] ${text} ${status.title}`;
            resolve(status);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getStatusImageFile(id: number) {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/work/status/${id}/image-file`)
        .then((data: any) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new StatusService();


import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import EstimateService from "@/services/estimate/estimate.service";

export default mixins(MixinsPageForm).extend({
  name: "Roulette",
  components: {},
  data: () => ({
    winnerList: [] as any,
  }),
  mounted() {
    //console.log("mounted");
    this.$nextTick(async () => {
      const estimate = (await EstimateService.get()) as any;
      if (estimate.eventWinnerList) {
        estimate.eventWinnerList.forEach((eventWinner) => {
          console.log("eventWinner : ", eventWinner);
          this.winnerList.push(eventWinner);
        });
      }
    });
  },
  methods: {},
});


import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import StatusService from "@/services/status/status.service";
import CategoryService from "@/services/category/category.service";
import core from "@/core";
import EstimateService from "@/services/estimate/estimate.service";

export default mixins(MixinsComponent).extend({
  name: "StatusComponent",
  data: () => ({
    toolbarHeight: 0,
    filter: {
      selectedIndexItems: [],
      selectedItems: new Map(),
      items: [] as any,
    },
    items: [] as any,
  }),
  mounted() {
    this.$nextTick(async () => {
      try {
        const estimate = (await EstimateService.get()) as any;
        const categoryNameList = estimate.categoryText.split(",");
        //console.log("categoryNameList : ", categoryNameList);

        const categoryList = (await CategoryService.getCategoryList()) as any;
        categoryList.forEach((category: any) => {
          if (categoryNameList.indexOf(category.name) > -1) {
            this.filter.items.push(category);
          }
        });

        this.items = (await StatusService.getStatusList()) as any;
      } catch (e) {
        console.log(e);
      }
    });
  },
  computed: {
    filtering() {
      if (this.filter.selectedItems.size == 0) return this.items;
      return this.items.filter((item: any) => {
        return this.filter.selectedItems.has(item.schedule.category.id);
      });
    },
  },
  watch: {
    "filter.selectedIndexItems"() {
      const selectedItems = new Map();
      this.filter.selectedIndexItems.forEach((index) => {
        const item: any = this.filter.items[index];
        selectedItems.set(item.id, item);
      });
      this.filter.selectedItems = selectedItems;
    },
  },
  methods: {
    fileSizeFormat(value: string) {
      return core.utils.format.filesize(true, parseInt(value), 2);
    },
  },
});


import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import core from "@/core";
import EstimatePrintComponent from "@/components/EstimatePrintComponent.vue";
import EstimateService from "@/services/estimate/estimate.service";

export default mixins(MixinsPage).extend({
  name: "EstimatePrint",

  components: {
    EstimatePrintComponent,
  },

  data: () => ({
    first: true,
    estimate: null as any,
    scheduleList: null as any,
  }),

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch("app/addPrint", this.isMobile ? "mobile" : "pc");

      this.getEstimate();
    });
  },
  updated() {
    if (this.first) {
      this.first = false;
      setTimeout(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("android") > -1 && userAgent.indexOf("scc") > -1) {
          core.mobile.call({ cmd: "print" });
        } else if (userAgent.indexOf("msie") > -1 || userAgent.indexOf("trident") > -1) {
          document.execCommand("print", true);
        } else {
          window.print();
        }
      }, 500);
    }
  },

  methods: {
    async getEstimate() {
      core.loader.show();
      try {
        this.estimate = await EstimateService.get();
        this.scheduleList = this.estimate.scheduleList;
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    },
  },
});

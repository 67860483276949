import core from "@/core";

class CategoryService {
  getCategoryList() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/work/category`)
        .then((data: any) => {
          const list = data.list;
          if (list != null) {
            resolve(list);
          } else {
            reject({
              message: "not found item",
            });
          }
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }
}

export default new CategoryService();

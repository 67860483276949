
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import store from "@/store";
import EstimateService from "@/services/estimate/estimate.service";
import CommentComponent from "@/components/CommentComponent.vue";
import core from "@/core";
import SupportScheduleModal from "@/modals/support/SupportScheduleModal.vue";
import CommentService from "@/services/comment/comment.service";
import SupportAsSelectCategoryModal from "@/modals/support/SupportAsSelectCategoryModal.vue";
import { EntityType, UpdateEventType } from "@/types";
import UpdateEvent from "@/models";

export default mixins(MixinsPageForm).extend({
  name: "Support",
  components: { SupportAsSelectCategoryModal, SupportScheduleModal, CommentComponent },
  data: () => ({
    app: store.state.app,
    title: "고객지원",
    id: 0,
    width: 0,

    estimate: null as any,
    modal: {
      schedule: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
      as: {
        visible: false,
        updateEvent: null as UpdateEvent | null,
      },
    },
    component: {
      commentEntity: {
        type: EntityType.ESTIMATE_SUPPORT,
        id: 0,
        visible: {
          imageHint: false,
        },
      },
    },
    visible: {
      workingDay: false,
    },
    helpBar: {
      visible: true,
      style: "",
      buttonStyle: "",
      emptyElStyle: "",
    },
  }),
  async mounted() {
    this.$nextTick(async () => {
      this.width = this.app.size.width - 20;
      // 고객지원
      try {
        const estimate = (this.estimate = (await EstimateService.get()) as any);
        this.component.commentEntity.id = estimate.id;
        const curMoment = core.date.instance();
        const dd = curMoment.format("dd");
        const hour = curMoment.hour();
        if (dd === "토" || dd === "일" || hour < 10 || hour >= 17) {
          this.visible.workingDay = true;
        }
      } catch (e) {
        console.log(e);
      }

      this.updateHelpBar();
    });
  },
  watch: {
    "app.size"() {
      this.width = this.app.size.width - 20;
    },
    async "modal.schedule.updateEvent"() {
      const event = this.getComponentUpdateEvent(this.modal.schedule);

      if (event?.result === UpdateEventType.CONFIRM) {
        const item = event.item;
        const text = this.dateListToText(item);
        // this.component.createComment = text;
        const params = {
          entityType: this.component.commentEntity.type,
          entityId: this.component.commentEntity.id,
          type: "T",
          content: `${text} 시공 가능일정을 선택했습니다.`,
        } as any;
        try {
          const result = await CommentService.create(params);
          //console.log("result : ", result);
        } catch (e) {
          console.log(e);
        }
      }
    },
  },
  methods: {
    dateListToText(dateList) {
      let text = "";
      const itemList = this.getDateItemList(dateList);
      itemList.forEach((item) => {
        let rangeDate = false;
        if (item.endAt != null) {
          const days = item.endAt.diff(item.startAt, "days");
          if (days > 0) {
            rangeDate = true;
          }
        }

        if (rangeDate) {
          text += `${item.startAt.format("YYYY년 MM월 DD일")} ~ ${item.endAt.format(
            "YYYY년 MM월 DD일"
          )}, `;
        } else {
          text += `${item.startAt.format("YYYY년 MM월 DD일")}, `;
        }
      });
      if (text.length > 1) {
        text = text.substr(0, text.length - 2);
      }
      return text;
    },
    getDateItemList(dateList) {
      const itemList = [] as any;
      let preDateMoment = null as any;
      let item = null as any;
      dateList.forEach((date) => {
        if (item != null) {
          const moment = core.date.instance(date);
          const days = moment.diff(preDateMoment, "days");
          if (days > 1) {
            item.endAt = preDateMoment;
            itemList.push(item);
            preDateMoment = null;
            item = null;
          }
        }

        if (item == null) {
          item = {
            startAt: core.date.instance(date),
            endAt: null,
          } as any;
        }
        preDateMoment = core.date.instance(date);
      });

      if (preDateMoment != null) {
        item.endAt = preDateMoment;
      }

      if (item != null) {
        itemList.push(item);
      }
      return itemList;
    },

    showSchedule() {
      const modal = this.modal.schedule;
      modal.visible = true;
    },
    showAs() {
      // const modal = this.modal.as;
      // modal.visible = true;
      // console.log("AS 요청");
      this.component.commentEntity.visible.imageHint = true;
    },
    toggleHelpBar() {
      this.helpBar.visible = !this.helpBar.visible;
      this.updateHelpBar();
    },
    updateHelpBar() {
      if (this.helpBar.visible) {
        this.helpBar.buttonStyle = "position: absolute; bottom: -10px; right: 10px";
        this.helpBar.style =
          "position: fixed; width: 100%; top: 50px; z-index: 1; min-height: 40px; background: #fff";
        if (this.visible.workingDay) {
          this.helpBar.emptyElStyle = "padding-top: 240px !important";
        } else {
          this.helpBar.emptyElStyle = "padding-top: 160px !important";
        }
      } else {
        this.helpBar.buttonStyle = "position: absolute; bottom: 0px; right: 10px";
        this.helpBar.style =
          "position: fixed; width: 100%; top: 50px; z-index: 1; min-height: 40px;";
        if (this.visible.workingDay) {
          this.helpBar.emptyElStyle = "padding-top: 90px !important";
        } else {
          this.helpBar.emptyElStyle = "";
          this.helpBar.style += "background: transparent";
        }
      }
    },
    statusToText(item) {
      // RECEIPT("접수"), CONFIRM("확인"), ADVICE("상담"), FIXED_SCHEDULE("일정확정"), COMPLETE("완료"), CANCEL("취소");
      const status = item.lastStatus.status;
      if (status === "RECEIPT") {
        return "접수중";
      } else if (status === "CONFIRM") {
        return "접수확인완료";
      } else if (status === "ADVICE") {
        return "상담중";
      } else if (status === "FIXED_SCHEDULE") {
        return "일정확정";
      } else if (status === "COMPLETE") {
        return "완료";
      }
      return "취소";
    },
  },
});

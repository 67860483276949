
import mixins from "vue-typed-mixins";
import AppBarProps from "@/components/AppBarProps.vue";

export default mixins(AppBarProps).extend({
  name: "AppBar",
  data: () => ({
    showMenu: false,
  }),
  computed: {
    title() {
      return this.$store.getters["topToolbar/title"];
    },
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    goBack() {
      this.$router.back();
    },
  },
});

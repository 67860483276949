import CoreHttp from "@/core/core-http";
import CoreUtils from "@/core/core-utils";
import CoreDate from "@/core/core-date";
import CoreLoader from "@/core/core-loader";
import CoreLocation from "@/core/core-location";
import CoreAlert from "@/core/core-alert";
import CoreMobile from "@/core/core-mobile";
import CoreWebSocket from "@/core/core-web-socket";

const core = {
  http: CoreHttp,
  utils: CoreUtils,
  date: CoreDate,
  loader: CoreLoader,
  location: CoreLocation,
  alert: CoreAlert,
  mobile: CoreMobile,
  webSocket: CoreWebSocket,
};

export default core;

import _history from "./history";
import config from "./config";
import { ErrorHandler, RawLocation } from "vue-router/types/router";

const eventRegister = function(router: any) {
  const routerPush = router.push.bind(router);
  const routerGo = router.go.bind(router);
  const routerReplace = router.replace.bind(router);
  const routerBack = router.back.bind(router);
  const routerForward = router.forward.bind(router);

  router.push = async (location: RawLocation, onComplete?: Function, onAbort?: ErrorHandler) => {
    // console.log("push");
    _history.action = config.pushName;
    // if (!routerNext()) {
    //   console.log("ignore action : push");
    //   return null;
    // }
    if (onComplete || onAbort) {
      return routerPush(location, onComplete, onAbort);
    }
    return routerPush(location).catch((error: any) => error);
  };

  router.go = async (n: number) => {
    // console.log("go");
    _history.action = config.goName;
    // if (!routerNext()) {
    //   console.log("ignore action : go");
    //   return null;
    // }
    const result = routerGo(n);
  };

  router.replace = async (location: RawLocation, onComplete?: Function, onAbort?: ErrorHandler) => {
    // console.log("replace");
    _history.action = config.replaceName;
    // if (!routerNext()) {
    //   console.log("ignore action : replace");
    //   return null;
    // }
    if (onComplete || onAbort) {
      return routerReplace(location, onComplete, onAbort);
    }
    return routerReplace(location).catch((error: any) => error);
  };

  router.back = async () => {
    // console.log("back");
    _history.action = config.backName;
    // if (!routerNext()) {
    //   console.log("ignore action : back");
    //   return null;
    // }
    routerBack();
  };

  router.forward = async () => {
    // console.log("forward");
    _history.action = config.forwardName;
    // if (!routerNext()) {
    //   console.log("ignore action : forward");
    //   return null;
    // }
    routerForward();
  };
};

export default eventRegister;
